define(['app', 'siteObj'], function(app, siteObj) {

  const config = {
    _instantSearchEndpoint: '/' + siteObj.customerLocale + '/' + siteObj.currencyType + '/instantsearch.nav?autocompleteRequest=',
    _lastKeyPress: null,
    _specialKeys: {
      'UP': 38,
      'DOWN': 40,
      'RETURN': 13,
      'ESC': 27
    },
    _results: [],
    _lastSearchString: null,
    _lastSearchTimeout: null,
    _minChars: 3,
    _visible: false,
    _activeClass: 'ac-active',
    _queryExec: false,
    _hiddenClassName: 'searchInputSuggestions-hidden',
    _selectedIndex: null,
    _previousSelected: null,
    _resultsClassName: '.ac-option'
  }

  class SearchInputSuggestions {
    constructor() {
      this.init = this.init.bind(this);
      this._blurEventHandler = this._blurEventHandler.bind(this);
      this._attachInstantSearch = this._attachInstantSearch.bind(this);
      this._show = this._show.bind(this);
      this._hide = this._hide.bind(this);
      this._bindEvents = this._bindEvents.bind(this);
      this._keyDownEventHandler = this._keyDownEventHandler.bind(this);
      this._keyUpEventHandler = this._keyUpEventHandler.bind(this);
      this._doSearch = this._doSearch.bind(this);
      this._keyNavigationHandler = this._keyNavigationHandler.bind(this);
      this._focusEventHandler = this._focusEventHandler.bind(this);
      this._blurEventHandler = this._blurEventHandler.bind(this);
      this._successCallback = this._successCallback.bind(this);
    }

    init(element) {
      this.element = element;
      this.config = config;
      this.config._searchField = element.querySelector('.searchInputSuggestions_searchText');

      // create a div to attach to body later (in common.js)
      this.divAutoComplete = document.createElement('div');
      //this.divAutoComplete.setAttribute('id', 'autocomplete');
      this.divAutoComplete.className = 'searchInputSuggestions_autocomplete';
      //switch off browser based autocomplete
      this.config._searchField.setAttribute('autocomplete', 'off');
      //safari tries to autocorrect spelling - pointless as we do this in ES anyway
      this.config._searchField.setAttribute('autocorrect', 'off');
      this._bindEvents();
      this._attachInstantSearch(this.divAutoComplete);

      const minSearchChars = this.config._searchField.getAttribute('data-min-chars');
      this.config._minChars = parseInt(minSearchChars) || config._minChars;
      app.subscribe('SearchInputSuggestions.hide', this._hide);
    };

    _attachInstantSearch(divAutoComplete) {
      // attach the autocomplete element to the DOM so it's ready to be populated

      const targetElement = this.element.querySelector('.searchInputSuggestions_searchContainer');
      targetElement.appendChild(divAutoComplete);
    }

    _show() {
      if (!this.config._visible && this.config._searchField !== null) {

        app.element.removeClass(this.config._hiddenClassName, this.divAutoComplete);
        this.config._visible = true;
      }
    }

    _hide () {
      if (this.config._visible) {
        app.element.addClass(this.config._hiddenClassName, this.divAutoComplete);
        if (this.config._previousSelected !== null) {
          // app.element.removeClass(this.config._activeClass, this.config._previousSelected.children[0]);
        }
        this.config._selectedIndex = null;
        this.config._visible = false;
      }
    }

    _bindEvents() {
      app.element.on('keydown', this._keyDownEventHandler, this.config._searchField);
      app.element.on('keyup', this._keyUpEventHandler, this.config._searchField);
      app.element.on('focus', this._focusEventHandler, this.config._searchField);
      app.element.on('blur', this._blurEventHandler, this.config._searchField);
    }


    _keyDownEventHandler(e) {
      this.config._lastKeyPress = e.keyCode;

      if (e.keyCode === this.config._specialKeys.RETURN) {
        // only change the behaviour if there is a selected item in the autocomplete list
        // otherwise it will interfere with the default form submit
        if (this.config._selectedIndex !== null) {
          (e.preventDefault) ? e.preventDefault() : e.returnValue = false;
          this.config._results[this.config._selectedIndex].children[0].click();
        }
      }
    }

    _keyUpEventHandler(e) {
      this.config._lastKeyPress = e.keyCode;

      switch (e.keyCode) {
        case this.config._specialKeys.UP:
          this._keyNavigationHandler('up');
          break;
        case this.config._specialKeys.DOWN:
          this._keyNavigationHandler('down');
          break;
        case this.config._specialKeys.ESC:
          this._hide();
          break;
        default:
          // only fire off ajax when valid keys are pressed - any letter or number or del or backspace
          if ((e.keyCode <= 90 && e.keyCode >= 48) || e.keyCode === 8 || e.keyCode === 46) {
            // no special keys were pressed, do as normal
            // set what the current search is as we need it for getting round the issue of any
            // default text in the search box before someone types in a valid search term
            this.config._lastSearchString = e.target.value;

            if (e.target.value.length >= this.config._minChars) {
              clearTimeout(this._lastSearchTimeout);
              // limit search requests to one per half second
              this._lastSearchTimeout = window.setTimeout(this._doSearch, 500);
            } else {
              this._hide();
            }
          }
          break;
      }
    }


    _doSearch() {
      this.config._queryExec = true;
      return app.ajax.get({
        url: this.config._instantSearchEndpoint + this.config._lastSearchString,
        dataType: 'JSON',
        error: function(response) {
          SearchInputSuggestions._errorCallback(response);
        },
        success: response => {
          this._successCallback(response);
        }
      });
    }


    _keyNavigationHandler(direction) {
      if (this.config._results.length) {

        if (direction === 'up') {
          if (this.config._selectedIndex === null || this.config._selectedIndex === 0) {
            this.config._selectedIndex = null;
          } else {
            this.config._selectedIndex--;
          }
        } else if (direction === 'down') {
          if (this.config._selectedIndex === null) {
            this.config._selectedIndex = 0;
          } else if (this.config._selectedIndex >= this.config._results.length - 1) {
            this.config._selectedIndex = this.config._results.length - 1;
          } else {
            this.config._selectedIndex++;
          }
        }

        if (this.config._previousSelected !== null) {
          app.element.removeClass(this.config._activeClass, this.config._previousSelected.children[0]);
        }

        if (this.config._selectedIndex !== null) {
          app.element.addClass(this.config._activeClass, this.config._results[this.config._selectedIndex].children[0]);
          this.config._previousSelected = this.config._results[this.config._selectedIndex];
        }
      }
    }

    _focusEventHandler(e) {
      if (this.config._queryExec && e.target.value.length >= this.config._minChars && e.target.value === this.config._lastSearchString) {
        this._show();
      }
    }

    _blurEventHandler() {
       window.setTimeout(() => {
         this._hide();
       }, 250);
    }

    _successCallback(response) {
      this.divAutoComplete.innerHTML = response;
      this._show();
      this.config._results = this.divAutoComplete.querySelectorAll(this.config._resultsClassName);
    }

    static _errorCallback(response) {
      console.log('error:' + response);
    }

  }

  return SearchInputSuggestions;
});
